<template>
  <v-row>
    <center v-if="loading" class="ma-12">
       <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
      ></v-progress-linear>
    </center>
    <v-col v-else cols="12">
      <v-form ref="form" v-model="validInvoiceForm" validation>
        <base-card>
        <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              top
              color="primary"
          ></v-progress-linear>
          <v-card-title>Create Invoice</v-card-title>
          <v-divider></v-divider>
          <div class="pa-10">
            <v-row>
              
              <v-col cols="12" md="6">
                <v-select 
                  :rules="[(v) => !!v || 'Item is required']"
                  required
                  v-model="formData.bpartnerId"
                  dense
                  :items="patners"
                  item-text="name"
                  item-value="bpartnerId"
                  label="Patner" />
              </v-col>
              
              <v-col cols="12" md="2">
                <v-select 
                  :rules="[(v) => !!v || 'Item is required']"
                  required
                  v-model="formData.currencyId"
                  dense
                  :items="getCurrencyList"
                  item-text="isoCode"
                  item-value="currencyId"
                  label="Currency" />
              </v-col>
              <v-col cols="4">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => !!v || 'Item is required']"
                    v-model="formData.duedate"
                    label="Due Date"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => !!v || 'Item is required']"
                    v-model="formData.subheading"
                    label="Sub Heading"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                    dense
                    v-model="formData.description"
                    :rows="2"
                    :row-height="20"
                    name="input-7-4"
                    label="Description"
                />
              </v-col>
              <v-row cols="12" md="12">
                <base-card class="ma-8" style="width: 96%;">
                  <v-card-title>Items</v-card-title>
                  
                  <div class="d-block align-center justify-space-between py-5">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Product ID</th>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Tax Rates</th>
                                    <th class="text-left">Quantity</th>
                                    <th class="text-left">Price</th>
                                    <th class="text-left">Total</th>
                                    <th class="text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in formData.items" :key="index">
                                    <td>{{ item.productId }}</td>
                                    <td class="text-no-wrap">
                                        <div class="d-flex align-center">
                                            <p class="ma-0">
                                                {{ productById(item.productId).name }}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="text-no-wrap">
                                      <small
                                        v-for="rate in item.taxRates"
                                        :key="rate"
                                        block
                                        class="d-block"
                                      >
                                        <small
                                          left class="pr-2">{{ taxRateById(rate).rate }}</small>
                                        {{ taxRateById(rate).name }}
                                      </small>
                                    </td>
                                    <td>
                                        <h6 class="text-success">
                                            {{ item.quantity }}
                                        </h6>
                                    </td>
                                    <td>
                                        <h6 class="text-success">
                                            {{ item.amount }}
                                        </h6>
                                    </td>
                                    <td>
                                        <h6 class="text-success">
                                            {{ item.quantity * item.amount }}
                                        </h6>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                          <!-- <v-tooltip top>
                                            <template v-slot:activator="{on, attrs}">
                                              <v-btn
                                                color="success"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                              >
                                                <v-icon>mdi-pencil-box-outline</v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Edit</span>
                                          </v-tooltip> -->
                                          <v-tooltip top>
                                            <template v-slot:activator="{on, attrs}">
                                              <v-btn
                                                color="danger"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                                @click="removeItem(index)"
                                              >
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Delete</span>
                                          </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                  <th colspan="5" class="text-right">Total</th>
                                  <th colspan="2" class="text-left">{{ invoiceTotal }}</th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                  </div>
                  <v-card-actions>
                    <v-dialog v-model="dialog" tabindex="-1" persistent max-width="600px">
                      <template v-slot:activator="{on, attrs}">
                        
                        <v-btn
                          class="rounded-lg py-5"
                          tile
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          >
                            <v-icon left class="pr-2">
                              mdi-plus
                            </v-icon>
                            Add Item
                        </v-btn>
                      </template>
                      <v-card class="">
                          <v-card-title>
                              <span class="headline">Add Item</span>
                          </v-card-title>
                          <v-card-text>
                              <v-form ref="itemForm" v-model="validItemForm" validation>
                                <v-container>
                                    <v-row>
                                      <v-col cols="12" md="12">
                                        <v-select 
                                          :rules="[(v) => !!v || 'Item is required']"
                                          required
                                          v-model="itemFormData.productId"
                                          dense
                                          @change="selectProduct"
                                          :items="products"
                                          item-text="name"
                                          item-value="productId"
                                          label="Product" />
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="itemFormData.quantity"
                                          label="Quantity"
                                          dense
                                          required
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="itemFormData.amount"
                                          label="Amount"
                                          dense
                                          required
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-select 
                                          :rules="[(v) => !!v || 'Item is required']"
                                          required
                                          v-model="itemFormData.taxRates"
                                          dense
                                          multiple
                                          chips
                                          :items="taxRates"
                                          item-text="name"
                                          item-value="taxId"
                                          label="Tax Rates" />
                                      </v-col>
                                    </v-row>
                                </v-container>
                              </v-form>
                              <small>*indicates required field</small>
                          </v-card-text>
                          <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  color="primary darken-1"
                                  text
                                  @click="dialog = false"
                                  >Close</v-btn
                              >
                              <v-btn
                                  color="primary darken-1"
                                  text
                                  :disabled="!validItemForm"
                                  @click="submitItem"
                                  >Save</v-btn
                              >
                          </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </base-card>
              </v-row>
              <v-col cols="12">
                <v-textarea
                    dense
                    v-model="formData.footer"
                    :rows="2"
                    :row-height="20"
                    name="input-7-4"
                    label="Footer"
                />
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-card-actions class="px-10 py-4">
            <v-spacer></v-spacer>
            
            <v-btn
            color="primary"
            :disabled="!validInvoiceForm"
            @click="submitInvoice()"
            >
            Submit
            </v-btn>
          </v-card-actions>
        </base-card>
      </v-form>
    </v-col>
  </v-row>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Invoice from './Invoice.vue';
export default {
  components: { Invoice },
  name: "PatnerForm",

  data: () => ({
    valid: false,
    invoicePreviewDialog: false,
    dialog: false,
    validItemForm: false,
    validInvoiceForm: false,
    formData: {
      invoiceId: 0,
      organizationId: 0,
      bpartnerId: 0,
      currencyId: 0,
      value: "",
      po_so: "",
      invoicedate: new Date().toISOString(),
      duedate: new Date().toISOString(),
      subheading: "",
      footer: "",
      description: "",
      items: [],
      active: true
    },
    itemFormData: {
      invoiceItemId: 0,
      invoiceId: 0,
      productId: 0,
      quantity: 1,
      taxRates: [],
      amount: 0,
      active: true
    }
  }),
  watch: {
    patnerSnackbarMessage (val) {
      if (val != null) {
        // this.makeToast("success", "Successfully added patner");
        console.log('snacked in successfully ')
        setTimeout(() => {
            
        }, 2000)
      }
    },
    invoice (val) {
      if (val != null) {
        // this.makeToast("success", "Successfully added patner");
        console.log('snacked in successfully ')
        setTimeout(() => {
            this.$router.push('/app/sales/view-invoice')
        }, 100)
      }
    },
  },
  mounted () {
    this.fetchCurrencies()
    this.fetchTaxRates()
    this.fetchProducts()
    this.fetchPatners()
  },
  computed: {
    ...mapGetters(['loading', 'loggedInUser', 'taxRates', 'invoice', 'patnerSnackbarMessage', 'getCurrencyList', 'products', 'patners']),
    invoiceTotal () {
        let amount = 0
        this.formData.items.forEach(element => {
            amount += element.amount * element.quantity
        });
        return amount
    }
  },
  methods: {
    ...mapActions(['fetchCurrencies', 'toggleLoading', 'fetchPatners', 'viewInvoice', 'createInvoice', 'fetchTaxRates', 'fetchProducts', 'togglePatnerSnackbarMessage']),
    taxRateById (id) {
      let taxRate
      this.taxRates.forEach(element => {
        if (element.taxId === id) {
          taxRate = element
        }
      });
      return taxRate
    },
    productById (id) {
      let product
      this.products.forEach(element => {
        if (element.productId === id) {
          product = element
        }
      });
      return product
    },
    selectProduct () {
      this.productById(this.itemFormData.productId).taxRates.forEach((element) => {
        this.itemFormData.taxRates.push(element.id)
      })
      this.itemFormData.amount = this.productById(this.itemFormData.productId).price
    },
    removeItem (index) {
      this.formData.items.splice(index, 1)
      this.formData.items.forEach((item) => {
        //
      })
    },
    previewInvoice () {
      this.viewInvoice(this.formData)
      this.invoicePreviewDialog = true
    },
    submitItem () {
      this.formData.items.push(this.itemFormData)
      this.itemFormData = {
        invoiceItemId: 0,
        invoiceId: 0,
        productId: 0,
        quantity: 1,
        taxRates: [],
        amount: 0,
        active: true
      }
      this.dialog = false
    },
    submitInvoice () {
      this.toggleLoading(true)
      this.formData.organizationId = this.loggedInUser.organizations[0].id
      this.createInvoice(this.formData)
      this.toggleLoading(false)
    },
  }
};
</script>
